:root {font-family: "Work Sans", sans-serif; }
body {
  margin: 0;
  font-family: "Work Sans", sans-serif !important;
}
html {scroll-behavior: smooth;}
.section-3 .title p, .section-4 .title p, .section-5 .title p{
  color: #ffffff;
  margin-top: 25px;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
}
.section-1 .title p{
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 25px;
  font-weight: 500;
}
h1, h2{
  font-weight: 800;
}
.logo{
  max-width: 180px;
}
.mt-minus{
  margin-top: -80px;
}
.downarrow{
  top: 30px;
    left: 48%;
}
// .downarrow img{ 
//       margin-top: -7px;
//   animation: arrow 3s infinite;
//   -webkit-animation: arrow 3s infinite; 
// }
// @keyframes arrow
// {
// 0% {opacity:0}
// 40% {opacity:1}
// 80% {opacity:0}
// 100% {opacity:0}
// }
// @-webkit-keyframes arrow 
// {
// 0% {opacity:0}
// 40% {opacity:1}
// 80% {opacity:0}
// 100% {opacity:0}
// }
// .downarrow .d3{
//   animation-delay:100ms;
//   -webkit-animation-delay:100ms;
// }
// .downarrow .d2{
//   animation-delay:400ms;
//   -webkit-animation-delay:400ms;
// }
// .downarrow .d1{
//   animation-delay:800ms;
//   -webkit-animation-delay:800ms;
// }
.levelup-bg{
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat; 
    margin-top: -1px;
}
.levelup-bg .levelup-text-sec{max-width: 1140px; margin: 0px auto; z-index: 1;}
.levelup-bg .levelup-text-sec .title{max-width: 630px;padding: 86px 0px;margin: 100px 0px;}
.levelup-bg.section-3{
// background-image: url('Assets/images/Acne-IQ.png'); 
background-image: url('Assets/images/levelup-bg.png'); 
// padding: 90px 0px;
}
.levelup-bg.section-5{
// background-image: url('Assets/images/XP-Boost.png'); 
background-image: url('Assets/images/levelup-bg.png'); 
}
.twitch-con{
  background-image: url('Assets/images/twitchconNewbg.png'); 
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat; 
    // background-color: rgba(67, 45, 168, 1);
    background-blend-mode: overlay; 
    background:  rgba(67, 45, 168, 1), url('Assets/images/sparkles.png');
}
a.learn-more-btn{
  padding: 15px 52px 15px 52px;
  gap: 10px;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  background: -webkit-linear-gradient(#000 5%, #fff 55%, #000 76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  text-shadow: 0px 0px 2px rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.7);
  &:hover{
    box-shadow: 0px 0px 90px 0px rgba(255, 255, 255, .9);
    border: 1px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1);
    -webkit-text-fill-color: rgba(0, 0, 0, 1);
  }
}
.section-1 h1{
  font-size: 70px;
  line-height: 76px;
  font-weight: 800;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8)), 
  radial-gradient(116.35% 15.44% at 26.87% 19.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.8) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
  radial-gradient(91.95% 13.59% at 26.87% 44.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
  radial-gradient(79% 15% at 29% 70%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
  radial-gradient(94% 14% at 28% 95%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
  radial-gradient(73.42% 15.6% at 26.87% 112%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.9) 61.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  filter: drop-shadow(3px 6px #41364A);
  max-width: 520px;
  text-transform: uppercase;
  max-width: 480px;
  letter-spacing: 0.5px;
}
.section-3 h2, .section-4 h2{
  font-size: 56px;
  line-height: 58px;
  font-weight: 800;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(111.79% 26.27% at 49.61% 89.41%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%), radial-gradient(112.38% 25.42% at 49.61% 38.14%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  filter: drop-shadow(2px 6px #41364A);
  max-width: 440px;
  text-transform: uppercase;
}
.section-5 h2{
  font-size: 56px;
  line-height: 58px;
  font-weight: 800;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)),
  radial-gradient(90.91% 15.54% at 26.36% 20.89%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.6) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(154.49% 14.82% at 26.36% 46%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(204.86% 15.71% at 26.36% 71%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
  radial-gradient(204% 15% at 26.36% 98%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  filter: drop-shadow(2px 6px #41364A);
  max-width: 440px;
  text-transform: uppercase;
}
//VideoSectionCss

.videosection {
  .vjs-error{
    .vjs-error-display{
        &:before{
          content: '' !important;
      }
    }
  }
  background-image: url('Assets/images/videosection-bg-new-large.png');
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: auto;
  background-size: cover;
  margin-top: -4.5%;
  position: relative;
}

.videosection .container{padding:80px 16px 230px 16px;}
.videosection h2 {
font-size: 46px;
line-height: 60px;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(111.79% 26.27% at 49.61% 89.41%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%), radial-gradient(112.38% 25.42% at 49.61% 38.14%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
color: #fff;
filter: drop-shadow(2px 6px #41364A);
}
#my-video-1{background-color: transparent;display: flex;justify-content: center; align-items: center;width: 100%; height: auto;max-width: 750px;}
#my-video-1.video-js{width: 100%; height: auto; max-width: 750px;}
#my-video-1 .vjs-control-bar{display: none;}
.vjs-paused.vjs-has-started button.vjs-big-play-button {display: block;}
.video-js button.vjs-big-play-button {
      background-color: transparent;
      border: 0px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
         transform: translateY(-50%);
}
.vjs-icon-play::before, .video-js .vjs-play-control .vjs-icon-placeholder::before, .video-js .vjs-big-play-button .vjs-icon-placeholder::before{
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 115 115" width="115" height="115"><style>.a{opacity:.6;fill:%23fff}.b{opacity:.6;fill:none;stroke:%23fff;stroke-width:2}</style><path class="a" d="m77.2 54.5c2.4 1.3 2.4 4.7 0 6l-36.9 21c-2.3 1.4-5.3-0.3-5.3-3v-42c0-2.7 3-4.4 5.3-3z"/><path class="b" d="m57 109c-28.8 0-52-23.2-52-52 0-28.8 23.2-52 52-52 28.8 0 52 23.2 52 52 0 28.8-23.2 52-52 52z"/></svg>');
    left: 50%;
    top: 50%;
    transform: translate(-50%, -58%);
    width: 110px;
    height: 110px;
}
.video-js:hover button.vjs-big-play-button, .video-js button.vjs-big-play-button:focus{background-color: transparent;}
.videosection .video-js .vjs-poster{background-size: cover;background-position: center center;z-index: -1;}
.videosection .video-js .vjs-tech {left: 50%;
  width: unset;
  height: calc(100% - 53px);
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
   -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
       transform: translateX(-50%);
       margin: 0px auto;
       box-shadow: 11px 11px 28px 0px #00000080;
      }
.bottom-4{
  bottom: 4%;
}
.dropdown-section-1{z-index: 999; cursor: pointer;}
.cookie-btn{background: none;border: 1px solid #00358f;}
li #ot-sdk-btn.cookie-settings-btn {
	background-color: rgba(255, 255, 255, 0.9);
	border: 0px none;
	color: #00358f;
	padding: 0px;
	font-weight: 700;
	font-size: 15px;
}
li #ot-sdk-btn.cookie-settings-btn:hover{
  background: transparent;
  color: #00358f;
}
.privacy-policy li::marker{font-size: 20px; line-height: 24px;}
// Footer css
.policy:hover{text-decoration: underline !important;}
.font-14{font-size: 14px;}
.font-15{font-size: 15px;}
.font-18{font-size: 18px;}
.font-20{font-size: 20px;}
.font-38{font-size: 38px;}
.lh-24px{line-height: 24px;}
.lh-25px{line-height: 25px;}
.lh-60px{line-height: 60px;}
.text-333{color: #333;}
.text-00358f{color: #00358f;}
.bg-7e02a9{background-color: #7e02a9;}
.fw-400{font-weight: 400;}
.fw-500{font-weight: 500;}
.fw-700{font-weight: 700;}
.list-style-type-none{list-style-type: none;}
.list-style-type-disc{list-style-type: disc;}
.max-w-386px{max-width: 386px;}
.max-w-290px{max-width: 290px;}
.max-w-420px{max-width: 420px;}
.max-w-450px{max-width: 450px;}
.max-w-530px{max-width: 530px;}
.max-w-700px{max-width: 700px;}
.max-w-730px{max-width: 730px;}
.gal-cookie-policy {
  table {
    th{
      background-color: #F8F8F8;
      border: 1px solid #d7d7d7;
      padding: 10px;
    }
    td{
      border: 1px solid #d7d7d7;
      padding: 10px;
      span{
        a{
          color: #00358f;
          font-weight: 700;
          text-decoration: none;
          font-size: 15px;
        }
      }
      a{
        color: #00358f;
        font-weight: 700;
        text-decoration: none;
        font-size: 15px;
      }
    }
    caption{
      font-size: 16px;
      line-height: 25px;
    }
  }
}
ul{
  &.p-title{
    li{
      p{
       span{
        font-size: 16px;
        line-height: 25px;
        color: #333333;
        font-weight: 700;
       }
       
      }
    }
  }  
}
.textbox-container{
  p{
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    span{
      font-size: 16px;
      line-height: 25px;
      color: #333333;
      font-weight: 700;
    }
    a{
      color: #00358f;
      font-weight: 700;
      text-decoration: none;
      font-size: 15px;
    }
  }
}
.cookie-policy-subtitle{
  font-size: 18px;
  padding: 25px 0 10px;
}

.vjs-error{
  .vjs-error-display{
      &:before{
        content: '' !important;
    }
  }
}
.levelup-right-section{
  position:absolute;
  top:0; 
  width:702px;
  right:20px;
}
//VideoSectionCss END
.level-up-product{
  margin:-35px 0 0 0;
  animation: key1 2.8s ease infinite alternate;
}
.levelup-bg.section-5 .levelup-text-sec .title p{max-width: 450px;}
.levelup-bg.section-5 .img-container-products{top: 50px;}
@media only screen and (max-width: 1200px){
  .section-1 h1 {font-size: 50px;line-height: 54px;}
  .level-up-product{
    margin:-16px 0 0 0;
    animation: key1 2.8s ease infinite alternate;
  }
}

@keyframes key1{
  0% {transform: translateY(0px);}
  100% {transform: translateY(-20px);}
}
@media only screen and (max-width: 992px){.videosection .container{padding: 70px 16px 100px 16px; }
.section-1 h1 {
  font-size: 56px;
  line-height: 58px;
  font-weight: 800;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8)), radial-gradient(116.35% 15.44% at 26.87% 19.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.8) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(91.95% 13.59% at 26.87% 44.8%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(79% 15% at 29% 70%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(94% 14% at 28% 95%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(73.42% 15.6% at 26.87% 112%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.9) 61.61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #fff;
  filter: drop-shadow(1px 3px #41364A);
  max-width: 520px;
  text-transform: uppercase;
}
.section-1 .title p {font-size: 18px;line-height: 21px;margin-top: 6px;}
.bottom-4 {bottom: 1.5%;}
.videosection{margin-top: -35px;}
}
@media only screen and (max-width: 991px){
  .Videobanner-mobile video{height: calc(100vh + 136px) !important; object-fit: cover;}
  .levelup-bg .levelup-text-sec .title{padding: 86px 0px 510px 0px; margin: 0px 0px 0px 0px;}
  .levelup-right-section{height: 500px;bottom: 130px;top: unset;}
  .levelup-bg.section-5 .levelup-text-sec .title{padding-bottom: 0px; margin-bottom: 0px !important;}
  .level-up-product {margin: -35px 0 0 0;}
  .videosection{margin-top: -44px;}
}
@media only screen and (max-width: 767px){
  .levelup-bg{
      background-image: url('Assets/images/level-up-mobile-bg.png');
  }
  .levelup-bg.section-3{background-image: url('Assets/images/level-up-mobile-bg.png');padding: 0px 0px; min-height: 80vh;}
}

@media only screen and (max-width: 768px){
  .video-js button.vjs-big-play-button{margin-top: unset;}
  .vjs-icon-play:before, .video-js button.vjs-big-play-button:before, .video-js .vjs-play-control:before {width: 280px;height: 225px;}
  .videosection .video-js .vjs-tech {height: 100%;}
    .footer-menu{justify-content: center; font-size: 12px;}
    .policy-row{font-size: 14px;}
    .w-md-50{width: 50% !important;}
    img.galdermaLogo{width: 50% !important;}
    .downarrow{left: 46%;}
    .section-1 .title p{font-size: 12px;line-height: 20px;margin-top: 10px;}
    .section-3 .title p, .section-4 .title p, .section-5 .title p{font-size: 12px;line-height: 20px;margin-top: 0px;}
    a.learn-more-btn {
      padding: 8px 26px 8px 26px;
      gap: 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
  }
  .levelup-bg .levelup-text-sec .title{padding: 0px 0px 560px 0px;}
}
@media only screen and (max-width: 640px){
      .bottom-4 {bottom: 40px;}
      .videosection {background-image: url('Assets/images/videosection-new-bg-mobile.png'); margin-top: -75px;}
      .videosection h2{font-size: 40px;line-height: 50px;max-width: 574px;}
      .vjs-icon-play:before, .video-js button.vjs-big-play-button:before, .video-js .vjs-play-control:before {height: 245px;}
      .videosection h2, .section-3 h2, .section-4 h2, .section-5 h2{ font-size: 34px; line-height: 40px;}
      .section-1 h1{
          font-size: 35px;
          line-height: 36px;
              }
     .videosection h2, .section-5 h2 {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)),
                    radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.6) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
                    radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
                    radial-gradient(204.86% 24% at 28% 84%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
                    radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: #fff;
        filter: drop-shadow(1px 3px #41364A);
     }
     .section-3 h2, .section-4 h2 {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), 
        radial-gradient(114% 44% at 49.61% 72%, rgba(0, 0, 0, 0) 41.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%), 
        radial-gradient(112.38% 25.42% at 49.61% -5.86%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.2) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: #fff;
        filter: drop-shadow(1px 3px #41364A);
     }
     .levelup-bg .levelup-text-sec .title{padding: 0px 0px 536px 0px;}
     
}
@media only screen and (max-width: 575px){
  .levelup-right-section{top: 190px;height: auto;}  
  .level-up-product {margin: -30px 0 0 0;}
}
@media only screen and (max-width: 546px){
  .videosection .container {padding: 116px 16px 192px 16px;}
  .videosection h2{width: 100% !important;}
  #my-video-1{max-width: 100%;padding: unset;height: 100%;}
  .videosection .video-js .vjs-tech {width: 100%;position: unset;transform: unset;}
  .levelup-right-section{top: 220px;}
}
@media only screen and (max-width: 475px){
  .levelup-bg.section-3{min-height: 72vh;}
  .levelup-bg .levelup-text-sec .title{padding: 0px 0px 480px 0px;}
  
  .level-up-product {margin: -20px 0 0 0;}
  .bottom-4 {bottom: 35px;}
}
@media only screen and (max-width: 428px){
  .levelup-right-section{top: 260px;}
  
}
@media only screen and (max-width: 414px){
  .levelup-right-section{top: 230px;}
  .levelup-bg .levelup-text-sec .title{padding: 0px 0px 400px 0px;}

}
@media only screen and (max-width: 375px){
  .levelup-bg.section-3{min-height: 72vh;}
  .levelup-bg .levelup-text-sec .title{padding: 0px 0px 370px 0px;}
  .levelup-right-section{top: 270px;}
  
  .bottom-4 {bottom: 35px;}
}
@media only screen and (max-width: 320px){
  .levelup-bg.section-3{min-height: 72vh;}
  .levelup-right-section{top: 320px;}
}
/* ==========================================
======  New Design Updates  =================*/



h2.fullTitle{
  background-image: linear-gradient(to top, #FFFFFF, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #bebebe, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @media only screen and (max-width: 767px){
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)), radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.6) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(204.86% 14% at 28% 84%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    max-width:400px;
  }
}


.dropdown-section-1 img.dropdown-icon{
  width:25px;
}


// ============== CES Section ================ 

.videosection{
    &.cesSection{
      background-image: url("Assets/images/cesSectionBg.png");
      height:953px;
      margin-top: -4.5%;
      background-position: center top;
      @media screen and (min-width: 1400px){
        height:1200px;
      }
      @media screen and (max-width: 640px){
        background-image: url("Assets/images/CesSectionMobileBG.png"); 
        height: 953px;
        margin-top: -73px !important;
        background-position: center center;
      }
      @media screen and (max-width: 413px){     
        height: 860px;
        margin-top: -70px !important;     
      }
      .container{
        height: 100%;
        padding:120px 16px 120px;
        @media screen and (max-width: 640px){
          padding:120px 30px 50px;
        }
        .row{
          height: 100%;
        }
      } 
      .title{  
        color:#fff;
        h2{
          text-transform: uppercase;
          font-size: 56px;
          font-weight: 800;    
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9019607843)), radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5019607843) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(198.86% 24% at -26% 91%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3019607843) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);      
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          @media screen and (max-width: 640px){
            background:linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9)), 
            radial-gradient(95% 22% at 26.36% -13%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.6) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
            radial-gradient(140% 26% at 26.36% 40%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.5) 56.42%, rgba(255, 255, 255, 0.2) 61.61%), 
            radial-gradient(204.86% 24% at 28% 87%, rgba(167, 55, 55, 0) 38.77%, rgba(0, 0, 0, 0.4) 56.42%, rgba(255, 255, 255, 0.2) 61.61%),
            radial-gradient(204% 15% at 26.36% 110%, rgba(0, 0, 0, 0) 38.77%, rgba(0, 0, 0, 0.3) 56.42%, rgba(255, 255, 255, 0.8) 61.61%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;          
            font-size: 34px;            
            line-height: 40px;
          }
        }   
        p{
          font-size: 16px;
          @extend .fw-500;
          color:#FFF;
          @media screen and (max-width:640px){
            font-size: 12px;
          }
        }    
      }
      .ceslogo{
        padding-bottom: 103px;
        @media screen and (max-width: 991px){
         padding:0px 50px 30px;        
        }
        @media screen and (max-width: 560px){
          padding: 0 30px 30px;
          
        }
      } 
    }
  }  

// ============== Featured Level up lineup Section. ================  

.lineup{
  padding-top:112px;
  padding-bottom:112px;
  @media screen and (max-width: 991px){
    padding-top:60px;
    padding-bottom:60px;
  }
  .title{
    max-width: 850px;
    h2{
      text-transform: uppercase;
      font-size: 56px;
      font-weight: 800;
      max-width: 100%;
      @media screen and (max-width: 640px){
        font-size: 34px;            
        line-height: 40px;
      }
    }
  }
  .prodimageWrap{
    background-image: url("Assets/images/STARBURST\ 1.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;  
    text-align: center;  
   
  }
  .Prodinfo{
    padding:20px 36px 20px 0px;
    .content{
      height:180px;  
      @media screen and (max-width:991px){
        height:auto;  
      }    
    }
   
    @media screen and (max-width:1024px){
      padding: 20px 25px 20px 0px;
    }
    @media screen and (max-width:640px){
      padding: 20px 0px;
    }
    h3{
      font-size: 19px;
      @extend .fw-700;
      color:#FFF;
      @media screen and (max-width:640px){
        font-size: 16px;
      }
    }
    p{
      font-size: 16px;
      @extend .fw-500;
      color:#FFF;
      @media screen and (max-width:640px){
        font-size: 12px;
      }
    }   
  }
}

// ============== See How section image ================  
.videosection.seehowUpdatedImage{
  margin-top:-1px; 
}
// ============== to set sound icon bottom right corner ================  
.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical{
  width:auto;
}
.videosection .video-js .vjs-tech{
  height: 100%;
  max-width: 100%;
  transform: unset;
  left: unset;
  position: relative;  
}
#my-video-1 .vjs-control-bar{
  background-color: transparent;  
  display: flex;
  justify-content: flex-end;  
} 
.my-video-1-dimensions.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 0px;
}
.video-js .vjs-play-control, .video-js .vjs-progress-control, .video-js .vjs-time-control, .video-js .vjs-playback-rate, .video-js .vjs-fullscreen-control, .video-js .vjs-volume-bar.vjs-slider-bar.vjs-slider{
    display: none; 
} 

.video-js .vjs-volume-panel.vjs-control.vjs-volume-panel-vertical.vjs-hover .vjs-volume-control.vjs-control.vjs-volume-vertical{
  background-color: transparent;
  height: unset;
  width: auto;
  left:auto;
}
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover, 
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active, 
.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active{
  width:auto;
}
.video-js .vjs-volume-panel.vjs-volume-panel-vertical,.video-js .vjs-control {
  width: auto;
}
.video-js .vjs-icon-volume-high:before, .video-js .vjs-mute-control .vjs-icon-placeholder:before{
  content:"";
    background-image: url("Assets/images/playsound.png");
    width: 90px;
    height: 90px;
    left: unset;
    top: unset;
    right: 0px;
    bottom:0px;
    position: absolute;    
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width:1024px){
      width: 70px;
      height: 70px;
    }    
    
}
.video-js .vjs-icon-volume-mute:before, .video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  content:"";
  background-image: url("Assets/images/mutesound.png");
 
}
// ============== to set sound icon bottom right corner end here================  

// ============== updated twichcon section ================  
.twitch-con{
  .container{
    padding:147px 60px 100px;
    @media screen and (max-width:1200px) {
      padding:147px 16px 100px;
    }
    @media screen and (max-width:991px) {
      padding:120px 16px;
    }
    @media screen and (max-width:560px) {
      padding:75px 26px;
    }
  }
  .title{
    text-align: center;
    @media screen and (max-width:991px) {
      text-align: left;
    }
    h2{
      text-transform: uppercase;
      font-size: 56px;
      font-weight: 800;
      max-width: 100%;
      @media screen and (max-width: 640px){
        font-size: 34px;            
        line-height: 40px;
      }
    }
    p{
      max-width:900px;      
      margin:14px auto 53px; 
      @media screen and (max-width:991px) {
        margin:14px auto 20px; 
      }
      &.twichconlastp{
        @media screen and (max-width:991px) {
          margin:-5px auto 35px;  
        }
            
      }
    }
  }
  img{
    width:100%;   
    border-radius: 6.62px;
  }
  .logowrap{
    max-width: 448px;
    margin: 0 auto;
    padding-bottom: 6px;
    @media screen and (max-width:560px) {
      max-width: 280px;
      margin: 0px;      
    }  
  }
}

// ============== Xpboost section ================  

.levelup-bg.section-5.xpBoost{
  background-image: url("../src/Assets/images/xpboostbg.png");
  @media only screen and (max-width: 767px){ 
    background-image: url('Assets/images/level-up-mobile-bg.png');
  }
  .downarrow{
    left:42%; 
    @media screen and (max-width:640px){
      top:0px;
    }
    @media only screen and (max-width:530px) {
      top:-20px;
    }
    .uparrow{
      width:42px;
      height: 40px;
    }
  }  
  .xpboostDeskImg{
    padding-top: 80px;
    position: relative;
    left: -30px;
    @media only screen and (max-width:1400px) {
      padding-top: 90px;     
      left: 0px;
    }
    img{
      @media only screen and (max-width:1200px) {
        width: 80%;
        margin-left: 20%;
      }  
    }
    .downarrow{
      left:48%;  
       
      @media only screen and (max-width:1200px) {
        left:50%;
      }
      
      
    } 
  }
  .levelup-text-sec{  
      max-width: 1200px;    
      @media screen and (max-width:1400px) {
        max-width:1140px;
        padding-left:10px;       
      } 
      @media screen and (max-width:1200px) {
        max-width:960px;
        padding-left:10px;
      }  
      @media screen and (max-width:767px) {
       
      }  
  }
  .imgwrapper {
    max-width: 100%;
      padding-left: 60px;
  }
}

// .video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-horizontal, .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal, .video-js .vjs-volume-panel .vjs-volume-control.vjs-slider-active.vjs-volume-horizontal{
//   width:auto;
// }

.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,
.video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal{
  width:auto;
}

.video-js .vjs-control {
  width: 0;
}

.vjs-picture-in-picture-control{
  display: none;
}